export const daznbetTheme = `
  --header-bg-golor: #1BB8FF;
  --header-color: #141414;
  --body-bg-color: #141414;
  --odd-cell-bg-color-highlighted: #1BB8FF;
  --odd-cell-color-highlighted: #141414;
  --button-focus-outline: #141414;
  --footer-notice-color: #FFFFFF;
  --footer-icons-color: #FFFFFF;
  --footer-legal-icons: #FFFFFF !important;
  --anchor-color: #1BB8FF;
  --odd-header-cell-color: white;
`;
