export const defaultTheme = `
  --font-family: Arial, Helvetica, sans-serif;
  --base-font-size: 1rem;
  --base-line-height: 1.2;

  --body-bg-color: #f4f4f4;
  --anchor-color: #005e94;

  --header-bg-golor: #363636;
  --header-color: #fff;

  --odd-cell-bg-color: #d1d1d1;
  --odd-cell-color: #20242e;
  --odd-header-cell-color: #20242e;
  --odd-cell-bg-color-highlighted: #616161;
  --odd-cell-color-highlighted: #fff;
  --button-focus-outline: #bbb3b3;

  --scrollbar-bg-color: transparent;
  --scrollbar-thumb-color: #98989e;

  --footer-notice-color: #000000;
  --footer-icons-color: #000000;

  --fallback-text-color: #000000;

  --anchor-color-exchange: var(--anchor-color);
  --body-bg-color-exchange: #ffb90c;
  --header-bg-golor-exchange: #000000;
  --header-color-exchange: #ffffff;
  --odd-cell-bg-color-exchange: #ffffff;
  --odd-cell-color-exchange: #20242e;
  --odd-cell-bg-color-highlighted-exchange: #161616;
  --odd-cell-color-highlighted-exchange: #ffffff;
  --footer-notice-color-exchange: #000000;
  --footer-icons-color-exchange: #000000;
`;
